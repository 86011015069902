@use "../../variables";

.page-graph {
  width: 100%;
  height: 520px;
  margin-bottom: 20px;
}

.card-graph,
.card-spinner {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.page-spinner {
  height: 520px;
  margin: 20px;
  position: relative;
}

.graph {
  border: 1px solid #ccc;
  background: #ffffff;
}

.graph-table-wrapper {
  padding: 20px;
  position: relative;
  //here
  background: variables.color("background");
}

.graph-table {
  position: relative;
}

div.card .graph-table {
  background-color: #ffffff;
}

.card .graph-table-wrapper {
  height: calc(100% - 40px);
  padding: 0px;
  overflow: auto;

  .graph-table {
    width: 100%;
    height: 100%;
  }
}

.page-heading {
  margin: 0px 0px 10px;
}

.page-subheading {
  margin: 0px;
  font-weight: normal;
}

.page-data {
  font-weight: bold;
}

.page-user-select {
  font-size: inherit;

  & option {
    font-size: 0.6em;
  }
}
